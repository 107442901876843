<template>
  <div class="molecule-detail-transfer-info">
    <bg-grid>
      <bg-grid-item :col="12">
        <div class="flex justify-space-between">
          <bg-text size="heading-3" class="inline-block">{{
            data.property_name
          }}</bg-text>

          <router-link :to="transferPendapatanRoute">
            <bg-button-icon icon="calendar" size="sm" icon-size="sm">
              Riwayat Transfer Pendapatan
            </bg-button-icon>
          </router-link>
        </div>
      </bg-grid-item>

      <bg-grid-item :col="12">
        <bg-card>
          <div class="flex justify-space-between align-center mb-16">
            <bg-text size="heading-5"
              >Informasi Transfer Pendapatan Properti</bg-text
            >
          </div>

          <bg-divider />

          <template>
            <bg-grid class="mt-24">
              <bg-grid-item :col="3">
                <bg-list-item title="ID">{{ data.approval_id }}</bg-list-item>
              </bg-grid-item>

              <bg-grid-item :col="3">
                <bg-list-item title="Model Kerja Sama">
                  <bg-text size="body-2" class="tt-capitalize">
                    {{ data.revenue_model_name | revenueTransformer }}
                  </bg-text>

                  <ul v-if="data.is_hybrid" class="list">
                    <li>
                      Booking:
                      <bg-text size="body-2" class="tt-capitalize">
                        {{ data.original_revenue_model_name }}
                      </bg-text>
                      Rate
                    </li>

                    <li>DBET: Commission Rate</li>
                  </ul>
                </bg-list-item>
              </bg-grid-item>

              <bg-grid-item :col="3">
                <bg-list-item title="Status Data Pendapatan">
                  <bg-label-rainbow
                    :color="data.status_color"
                    class="tt-capitalize"
                  >
                    {{ approvalTranslation[data.status] }}
                  </bg-label-rainbow>
                </bg-list-item>
              </bg-grid-item>

              <bg-grid-item :col="3">
                <bg-list-item title="Status Transfer">
                  <bg-label-rainbow
                    :color="data.transfer_status_color"
                    class="tt-capitalize"
                  >
                    {{ data.transfer_status | transferStatusText }}
                  </bg-label-rainbow>
                </bg-list-item>
              </bg-grid-item>

               <bg-grid-item :col="3">
                <bg-list-item title="Add On">
                  <ul class="list">
                    <li>{{ jpType }}</li>
                    <li>{{ adpType }}</li>
                  </ul>
                </bg-list-item>
              </bg-grid-item>

              <bg-grid-item :col="3">
                <bg-list-item title="Tipe Transfer Pendapatan">
                  {{ periodType }}
                </bg-list-item>
              </bg-grid-item>

              <bg-grid-item :col="6">
                <bg-list-item title="Tanggal Transfer Pendapatan & Cut Off">
                  {{ transferAndCutOffDates }}
                </bg-list-item>
              </bg-grid-item>
            </bg-grid>
          </template>
        </bg-card>
      </bg-grid-item>
    </bg-grid>
  </div>
</template>

<script>
import {
  BgGrid,
  BgGridItem,
  BgCard,
  BgText,
  BgDivider,
  BgListItem,
  BgLabelRainbow,
  BgButtonIcon,
} from 'bangul-vue';

import { dateFormatterToDisplay, dateRangeToDisplay } from 'Utils/formatter';

import DISBURSEMENT_PERIOD_TYPES from '@admin/pages/PaymentDisbursementApproval/constants/disbursement-period-types';

export default {
  name: 'DetailTransferInfo',

  components: {
    BgGrid,
    BgGridItem,
    BgCard,
    BgText,
    BgDivider,
    BgListItem,
    BgLabelRainbow,
    BgButtonIcon,
  },

  props: {
    data: {
      type: Object,
      default: /* istanbul ignore next */ () => {},
    },
  },

  filters: {
    transferStatusText: value => {
      if (value) {
        return value.replace(/_/g, ' ');
      }

      return '-';
    },
    revenueTransformer: value => {
      if (value !== 'hybrid') {
        return `${value} Rate`;
      }

      return value;
    },
  },

  computed: {
    adpType() {
      let type = 'No ADP';
      if (this.data.is_adp && this.data.adp_type === 'ADP_3_MO') {
        type = 'ADP 3 Month';
      }
      if (this.data.is_adp && this.data.adp_type === 'ADP_6_MO') {
        type = 'ADP 6 Month';
      }
      return type;
    },
    jpType() {
      let type = 'No JP';
      if (
        this.data.is_income_guarantee &&
        this.data.income_guarantee_type === 'JP_PARTIAL'
      ) {
        type = 'Partial JP';
      }
      if (
        this.data.is_income_guarantee &&
        this.data.income_guarantee_type === 'JP_FULL_A'
      ) {
        type = 'Full JP A';
      }
      if (
        this.data.is_income_guarantee &&
        this.data.income_guarantee_type === 'JP_FULL_B'
      ) {
        type = 'Full JP B';
      }
      return type;
    },
    transferPendapatanRoute() {
      return {
        name: 'property-detail.overview',
        hash: '#transfer-pendapatan-history',
        params: {
          propertyId: this.data.property_id,
        },
      };
    },
    approvalTranslation() {
      return {
        approved: 'Dikonfirmasi',
        unapproved: 'Menunggu Konfirmasi',
        approved_by_system: 'Dikonfirmasi Sistem'
      }
    },
    periodType() {
      const { period = '' } = this.data;

      const periodType = DISBURSEMENT_PERIOD_TYPES.find(
        item => item.val === period
      );

      return periodType?.label || '-';
    },
    transferAndCutOffDates() {
      const { schedule_date, cutoff_start, cutoff_end } = this.data;

      const dateFormat = 'D MMM YYYY';

      const transferDate =
        `Tanggal ${dateFormatterToDisplay(schedule_date, dateFormat)}`;
      const cutoffDate =
        `Cut Off: ${dateRangeToDisplay(cutoff_start, cutoff_end)}`;

      return `${transferDate} (${cutoffDate})`;
    }
  },
};
</script>

<style lang="scss" src="./DetailTransferInfo.scss" scoped />
